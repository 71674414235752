/*---------------------------------------------------------
    Home Page Center Menu
---------------------------------------------------------*/
.menuPanel {
  height: 520px;
  width: 1000px;
}

/*.menuPanel span.disabled:hover {
    content: "Coming Soon";
  }*/
