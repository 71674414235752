/*---------------------------------------------------------
    Top Navbar 
---------------------------------------------------------*/
header .navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  background-image: linear-gradient(#0075aa, #03567b);
  height: 116px;
  font-size: 12px !important;
  font-weight: 600;
}

header .navbar-brand {
    background-image: url("../images/stewart-connect-logo-white.svg") !important;
    width: 302;
    height: 70px;
}

header .navbar-toggler {
    background-color: transparent !important;
}

header .navbar-toggler-icon {
  font-weight: bold;
  font-size: larger;
}

header .nav-item {
  text-align: center;
}

header .nav-item a {
  height: 116px; /* 42px */
  width: 116px;
  color: #fff !important;
  text-align: center;
  /*text-shadow: 0 1px 0 rgba(234, 243, 247, .2);*/
}

header .nav-item a:hover {
  background-color: rgba(255, 255, 255, 0.6); /* white with 20% opaque */
  text-decoration: none;
  color: #9e2339 !important;
}

/*  override .nav-link.disabled styles  */
header .nav-item .disabled {
  pointer-events: auto; /* allow hover effect */
  color: #8c8c8c !important;
}

header .nav-item a span {
  display: block;
  line-height: 1;
}

/*  Name on Navbar  */
header .nav-name {
  text-align: center;
  display: table;
  font-size: 18px !important;
  font-weight: 600;
}

header .nav-name a {
  height: 116px;
  width: 116px;
  color: #fff !important;
  /*text-shadow: 0 1px 0 rgba(234, 243, 247, .2);*/
  display: table-cell;
  vertical-align: middle;
}

header .nav-name a:hover {
  background-color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  color: #9e2339 !important;
}

a#menuOrderProducts::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/createfile-shopping-bag-sm-white.svg");
  display: block;
  margin-top: 30px;
}

a#menuOrderProducts:hover::before {
  content: url("../images/icons/rollover/createfile-shopping-bag-sm-red.svg");
}

a#menuMyFiles::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/files-folders-sm-white.svg");
  display: block;
  margin-top: 30px;
}

a#menuMyFiles:hover::before {
  content: url("../images/icons/rollover/files-folders-sm-red.svg");
}

a#menuMyContacts::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/contacts-address-book-sm-gray.svg");
  display: block;
  margin-top: 30px;
}

a#menuMyContacts:hover::before {
  /*content: url('../images/icons/rollover/my-contacts-red-sm.png');*/
  content: url("../images/icons/rollover/coming-soon-sm-red.svg");
}

a#menuHelpCenter::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/question-circle-lg-blue.svg");
  display: block;
  margin-top: 30px;
}

a#menuHelpCenter:hover::before {
  content: url("../images/icons/rollover/question-circle-lg-red.svg");
}

a#menuMyPayments::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/file-invoice-dollar-connect-sm-gray.svg");
  display: block;
  margin-top: 30px;
}

a#menuMyPayments:hover::before {
  /*content: url('../images/icons/rollover/my-payments-red-sm.png');*/
  content: url("../images/icons/rollover/coming-soon-sm-red.svg");
}

a#menuReports::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/reports-file-alt-Connect-sm-gray.svg");
  display: block;
  margin-top: 30px;
}

a#menuReports:hover::before {
  content: url('../images/icons/rollover/reports-red-sm.svg');
  /* content: url("../images/icons/rollover/coming-soon-sm-red.svg"); */
}

a#menuMyProfile::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/profile-user-circle-sm-white.svg");
  display: block;
  margin-top: 30px;
}

a#menuMyProfile:hover::before {
  content: url("../images/icons/rollover/profile-user-circle-sm-red.svg");
}

a#menuSignOut::before {
  border: 0;
  background-color: transparent;
  content: url("../images/icons/base/sign-out-alt-sm-white.svg");
  display: block;
  margin-top: 30px;
}

a#menuSignOut:hover::before {
  content: url("../images/icons/rollover/sign-out-alt-sm-red.svg");
}

/* Hamburger Menu section */
@media (max-width: 1200px) {
  #collapsibleNavbar {
    position: absolute;
    top: 116px;
    left: 0px;
    width: 200px;
    background-color: #e8e5e5;
  }

  #collapsibleNavbar .nav-item a {
    width: auto !important;
    height: auto !important;
    text-align: left;
    padding-left: 15px;
    color: var(--activeBlue) !important;
  }

  #collapsibleNavbar .nav-item a:hover {
    color: #9e2339 !important;
  }

  #collapsibleNavbar .nav-item .disabled {
    pointer-events: auto; /* allow hover effect */
    color: #8c8c8c !important;
  }

  #collapsibleNavbar .nav-item a span {
    display: inline;
    width: 100% !important;
  }

  #collapsibleNavbar .nav-item a::before {
    display: inline !important;
    vertical-align: middle !important;
  }

  a#menuOrderProducts::before {
    content: url("../images/icons/base/createfile-shopping-bag-sm-blue.svg");
  }

  a#menuOrderProducts span {
    padding-left: 25px !important;
  }

  a#menuMyFiles::before {
    content: url("../images/icons/base/files-folders-sm-blue.svg");
  }

  a#menuMyFiles span {
    padding-left: 15px !important;
  }

  a#menuMyContacts::before {
    padding-right: 3px !important;
  }

  a#menuMyContacts:hover::before {
    padding-right: 0px !important;
  }

  a#menuMyContacts span {
    padding-left: 22px !important;
  }

  a#menuHelpCenter::before {
    padding-right: 3px !important;
  }

  a#menuHelpCenter:hover::before {
    padding-right: 0px !important;
  }

  a#menuHelpCenter span {
    padding-left: 22px !important;
  }

  a#menuMyPayments::before {
    padding-right: 5px !important;
  }

  a#menuMyPayments:hover::before {
    padding-right: 0px !important;
  }

  a#menuMyPayments span {
    padding-left: 22px !important;
  }

  a#menuReports::before {
    padding-right: 5px !important;
  }

  a#menuReports:hover::before {
    padding-right: 0px !important;
  }

  a#menuReports span {
    padding-left: 22px !important;
  }

  a#menuMyProfile::before {
    content: url("../images/icons/base/profile-user-circle-sm-blue.svg");
  }

  a#menuMyProfile span {
    padding-left: 23px !important;
  }
}
