.typeSignature > span {
  width: 433px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto",
    sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: left;
  line-height: normal;
}

.typeSignature {
  width: 471px;
  height: 27px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: normal;
}

.typeSignatureNameInput {
  width: 498px;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  color: #adadad;
  text-align: left;
}

.rectangle {
  align-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  line-height: normal;
  padding: 0 12px;
  text-align: center;
  vertical-align: middle;
  width: 278px;
}

.selected {
  border: 1px solid #0075aa !important;
}

.rectangle > div > div > div > canvas {
  max-width: 190px !important;
  cursor: pointer;
}

.typeSection {
  margin: 32px 147px 33px 249px;
  display: contents;
}
