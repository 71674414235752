.error {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  color: black;
  font-size: 19px;
  font-weight: 400;
  padding-left: 16px;
  margin-bottom: 0;
  margin-top: 5px;
}

.sectionSubTitle,
.tableHeader {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.sectionSubTitle {
  margin-top: 0 !important;
}

.sectionText {
  margin-top: 5px !important;
  color: #797979;
  font-size: 16px;
}

.activeItem {
  background-color: #0075aa;
  color: white;
}
