@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bungee&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Damion&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.cdnfonts.com/css/freestyle-script);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap);
/*---------------------------------------------------------
    Footer
---------------------------------------------------------*/
.copyright {
  font-size: 14px;
  margin-left: 16px;
}

footer {
  flex-shrink: 0;
}

footer .links a {
  color: #0075aa;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  padding-right: 20px;
}

footer .links a:hover {
  color: #8c8c8c;
  text-decoration: none;
}

footer .socialMediaButtons {
  font-size: 32px;
}

footer .socialMediaButtons a {
  font-size: 32px;
  padding-right: 30px;
  color: #808285;
}

#footerFacebook:hover {
  color: #47639b;
}

#footerTwitter:hover {
  color: #4b9ed7;
}

#footerLinkedIn:hover {
  color: #2174ae;
}

#footerYoutube:hover {
  color: #ee3924;
}

#footerRss:hover {
  color: #fdb515;
}

/*---------------------------------------------------------
    Top Navbar 
---------------------------------------------------------*/
header .navbar {
  padding-top: 0px;
  padding-bottom: 0px;
  padding-right: 0px;
  background-image: linear-gradient(#0075aa, #03567b);
  height: 116px;
  font-size: 12px !important;
  font-weight: 600;
}

header .navbar-brand {
    background-image: url(/static/media/stewart-connect-logo-white.2b3f0bfd.svg) !important;
    width: 302;
    height: 70px;
}

header .navbar-toggler {
    background-color: transparent !important;
}

header .navbar-toggler-icon {
  font-weight: bold;
  font-size: larger;
}

header .nav-item {
  text-align: center;
}

header .nav-item a {
  height: 116px; /* 42px */
  width: 116px;
  color: #fff !important;
  text-align: center;
  /*text-shadow: 0 1px 0 rgba(234, 243, 247, .2);*/
}

header .nav-item a:hover {
  background-color: rgba(255, 255, 255, 0.6); /* white with 20% opaque */
  text-decoration: none;
  color: #9e2339 !important;
}

/*  override .nav-link.disabled styles  */
header .nav-item .disabled {
  pointer-events: auto; /* allow hover effect */
  color: #8c8c8c !important;
}

header .nav-item a span {
  display: block;
  line-height: 1;
}

/*  Name on Navbar  */
header .nav-name {
  text-align: center;
  display: table;
  font-size: 18px !important;
  font-weight: 600;
}

header .nav-name a {
  height: 116px;
  width: 116px;
  color: #fff !important;
  /*text-shadow: 0 1px 0 rgba(234, 243, 247, .2);*/
  display: table-cell;
  vertical-align: middle;
}

header .nav-name a:hover {
  background-color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  color: #9e2339 !important;
}

a#menuOrderProducts::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/createfile-shopping-bag-sm-white.b69a8f0e.svg);
  display: block;
  margin-top: 30px;
}

a#menuOrderProducts:hover::before {
  content: url(/static/media/createfile-shopping-bag-sm-red.ba7b2355.svg);
}

a#menuMyFiles::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/files-folders-sm-white.7832c06a.svg);
  display: block;
  margin-top: 30px;
}

a#menuMyFiles:hover::before {
  content: url(/static/media/files-folders-sm-red.22cfc832.svg);
}

a#menuMyContacts::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/contacts-address-book-sm-gray.8a9a2152.svg);
  display: block;
  margin-top: 30px;
}

a#menuMyContacts:hover::before {
  /*content: url('../images/icons/rollover/my-contacts-red-sm.png');*/
  content: url(/static/media/coming-soon-sm-red.eb4c49dd.svg);
}

a#menuHelpCenter::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/question-circle-lg-blue.ecab43fc.svg);
  display: block;
  margin-top: 30px;
}

a#menuHelpCenter:hover::before {
  content: url(/static/media/question-circle-lg-red.a7611940.svg);
}

a#menuMyPayments::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/file-invoice-dollar-connect-sm-gray.c1c82c57.svg);
  display: block;
  margin-top: 30px;
}

a#menuMyPayments:hover::before {
  /*content: url('../images/icons/rollover/my-payments-red-sm.png');*/
  content: url(/static/media/coming-soon-sm-red.eb4c49dd.svg);
}

a#menuReports::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/reports-file-alt-Connect-sm-gray.fee44431.svg);
  display: block;
  margin-top: 30px;
}

a#menuReports:hover::before {
  content: url(/static/media/reports-red-sm.a5a4e114.svg);
  /* content: url("../images/icons/rollover/coming-soon-sm-red.svg"); */
}

a#menuMyProfile::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/profile-user-circle-sm-white.2572a3a7.svg);
  display: block;
  margin-top: 30px;
}

a#menuMyProfile:hover::before {
  content: url(/static/media/profile-user-circle-sm-red.1f24b974.svg);
}

a#menuSignOut::before {
  border: 0;
  background-color: transparent;
  content: url(/static/media/sign-out-alt-sm-white.d952df76.svg);
  display: block;
  margin-top: 30px;
}

a#menuSignOut:hover::before {
  content: url(/static/media/sign-out-alt-sm-red.e403c716.svg);
}

/* Hamburger Menu section */
@media (max-width: 1200px) {
  #collapsibleNavbar {
    position: absolute;
    top: 116px;
    left: 0px;
    width: 200px;
    background-color: #e8e5e5;
  }

  #collapsibleNavbar .nav-item a {
    width: auto !important;
    height: auto !important;
    text-align: left;
    padding-left: 15px;
    color: var(--activeBlue) !important;
  }

  #collapsibleNavbar .nav-item a:hover {
    color: #9e2339 !important;
  }

  #collapsibleNavbar .nav-item .disabled {
    pointer-events: auto; /* allow hover effect */
    color: #8c8c8c !important;
  }

  #collapsibleNavbar .nav-item a span {
    display: inline;
    width: 100% !important;
  }

  #collapsibleNavbar .nav-item a::before {
    display: inline !important;
    vertical-align: middle !important;
  }

  a#menuOrderProducts::before {
    content: url(/static/media/createfile-shopping-bag-sm-blue.23fc945e.svg);
  }

  a#menuOrderProducts span {
    padding-left: 25px !important;
  }

  a#menuMyFiles::before {
    content: url(/static/media/files-folders-sm-blue.f10adb06.svg);
  }

  a#menuMyFiles span {
    padding-left: 15px !important;
  }

  a#menuMyContacts::before {
    padding-right: 3px !important;
  }

  a#menuMyContacts:hover::before {
    padding-right: 0px !important;
  }

  a#menuMyContacts span {
    padding-left: 22px !important;
  }

  a#menuHelpCenter::before {
    padding-right: 3px !important;
  }

  a#menuHelpCenter:hover::before {
    padding-right: 0px !important;
  }

  a#menuHelpCenter span {
    padding-left: 22px !important;
  }

  a#menuMyPayments::before {
    padding-right: 5px !important;
  }

  a#menuMyPayments:hover::before {
    padding-right: 0px !important;
  }

  a#menuMyPayments span {
    padding-left: 22px !important;
  }

  a#menuReports::before {
    padding-right: 5px !important;
  }

  a#menuReports:hover::before {
    padding-right: 0px !important;
  }

  a#menuReports span {
    padding-left: 22px !important;
  }

  a#menuMyProfile::before {
    content: url(/static/media/profile-user-circle-sm-blue.d622a1ee.svg);
  }

  a#menuMyProfile span {
    padding-left: 23px !important;
  }
}

/*---------------------------------------------------------
    Home Page Center Menu
---------------------------------------------------------*/
.menuPanel {
  height: 520px;
  width: 1000px;
}

/*.menuPanel span.disabled:hover {
    content: "Coming Soon";
  }*/

:root {
  --bodyWidth: 100%;
}

html,
body {
  height: 100%;
  background-color: #E5E5E5;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #F9F9F9;
  box-shadow: 1px 0px 0px 0px #DEDEDE inset;
}

::-webkit-scrollbar-thumb {
  border-radius: 9px;
  background-color: #00000099;
  border: 3px solid transparent;
  background-clip: content-box;
}

#root {
  height: 100%;
  font-family: "Roboto" !important;
  font-size: 14px !important;
  display: flex;
  flex-direction: column;
}

input.MuiInputBase-input,
div.MuiSelect-select,
div.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 16px;
}

main {
  flex: auto;
}

.errorBorder {
  border: 1px solid #E00000 !important;
}

.errorMessage {
  color: #E00000 !important;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  position: absolute;
}

.endorsementGridErrorMessage {
  color: #E00000 !important;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
}

.styledErrorMessage {
  color: #E00000 !important;
  font-size: 12px;
  font-weight: 400;
  padding-left: 10px;
  position: relative;
}

.dateValidation {
  width: 250px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dateValidation:hover {
  width: 100%;
}

.errorMessageBigRed {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 10px;
  left: 0px;
  top: 3px;
  width: 100%;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  z-index: 99;
  text-align: left;
}

.errorMessageBigRedFloating {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 190px;
}

.errorMessageBigRedFloating210 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 210px;
}

.errorMessageBigRedFloatingLabel {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 23px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  float: left;
  margin-bottom: -100%;
  margin-left: -40%;
  z-index: 99;
  text-align: left;
  width: 190px;
}

.errorMessageBigRedFloatingCoInsurance {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  background-color: #E00000 !important;
  border-width: 0px;
  position: relative;
  padding: 5px 5px 5px 15px;
  left: 60px;
  top: 3px;
  height: auto;
  background: inherit;
  border: none;
  border-radius: 6px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.349019607843137);
  /* float: left; */
  margin-bottom: -100%;
  margin-left: -150px;
  margin-top: -8px;
  z-index: 99;
  text-align: left;
  max-width: 190px;
}

.MuiSelect-select.MuiInput-input {
  padding-right: 30px;
}

@media (min-width: 1200px) {
  .container {
    max-width: 90% !important;
  }
}

#spinner {
  position: absolute;
  z-index: 99;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.editableSegmentControl {
  width: 100%;
  font-size: 18px;
  display: inline-flex;
  position: relative;
  flex-direction: column;
}

.editableSegmentControl>label {
  padding-left: 12px;
  color: #adadad;
  z-index: 1;
  font-weight: 400;
  line-height: 1;
  font-size: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 28px;
  left: 0;
  position: absolute;
  visibility: hidden;
  opacity: 0;
}

.editableSegmentControl>label.shrink {
  -webkit-transform: translate(0, -2px) scale(0.7777777777777778);
          transform: translate(0, -2px) scale(0.7777777777777778);
  margin-left: -12px;
  top: 0;
  color: black;
  padding-left: unset;
  visibility: visible;
  opacity: 1;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    opacity 200ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, opacity 200ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, opacity 200ms,
    -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.segmentInputAccordion .tooltip-x {
  display: none;
}

.segmentInputAccordion .tooltip-x>div {
  color: rgba(0, 0, 0, 0.87);
  border: 1px;
  font-size: 14px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-color: #f2f2f2;
  background-color: #ffffff;
}

.segmentInputAccordion .tooltip-x>div .MuiTooltip-arrow {
  color: #fff;
  background-color: transparent;
}

.segmentInputAccordion.showTooltip .tooltip-x {
  display: block;
}

.segmentContainer::before {
  content: attr(data-placeholder);
  color: #adadad;
}

.segmentContainer:focus {
  text-overflow: clip;
}

.segmentContainer:not(:focus) {
  text-overflow: ellipsis;
}

.editableSegmentControl>.segmentInputAccordion {
  margin-top: 16px;
  position: relative;
}

.editableSegmentControl ul {
  list-style-type: none;
  font-size: 18px;
  margin-top: 15px;
}

.editableSegmentControl li {
  display: flex;
  align-items: center;
}

/* .editableSegmentControl .card-body input[type="checkbox"] {
  visibility: none;
} */
.checkboxFrame {
  height: 16px;
  width: 16px;
  min-width: 16px;
  margin-bottom: 3px;
  margin-right: 10px;
  margin-left: -32px;
  display: flex;
  align-items: center;
  border: 1px solid #7f7f7f;
  border-radius: 2px;
}

.segmentInputAccordion {
  border: none;
  background: none;
  display: flex;
  flex-direction: column-reverse;
  overflow: visible;
  position: relative;
}

.segmentInputAccordion .content {
  padding: 0px;
  margin-bottom: 0 !important;
  border: solid 1px white;
  border-radius: 6px !important;
  background-color: white;
}

.segmentInputAccordion.disabled .content {
  background-color: #f2f2f2;
  border: solid 1px #f2f2f2;
  color: #626262;
  height: 38px;
}

.segmentInputAccordion.disabled .content:hover {
  border: solid 1px #f2f2f2;
}

.segmentInputAccordion .content:hover {
  border: solid 1px rgb(0, 117, 170);
}

.editableSegmentControl.dd-show {
  margin-top: 10px;
  min-height: 47px;
}

.segmentInputAccordion.dd-show {
  position: absolute;
  top: 6px;
  margin-top: 0;
  width: 100%;
  max-height: 62px;
}

label.dd-show {
  margin-top: -10px;
}

.segmentInputAccordion .content.dd-show {
  border: solid 1px rgb(0, 117, 170);
  border-bottom-color: white;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.segmentContainer {
  white-space: nowrap;
  overflow: hidden;
  height: 26px;
  font-size: 18px;
  outline: 0px solid transparent;
}

.segmentContainer br {
  display: none;
}

.segmentContainer .segment {
  display: inline;
  white-space: nowrap;
}

.segmentInputAccordion .content.dd-show,
.segmentInputAccordion .content.dd-show .segmentContainer,
.segmentInputAccordion .content.dd-show .segment {
  white-space: normal !important;
  overflow: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.segmentInputAccordion .dropdownWrapper .dropdown {
  position: absolute;
  width: 100%;
  border: solid 1px rgb(0, 117, 170);
  background-color: white;
  /* border-top: none; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 11;
}

.segmentInputAccordion .dropdownWrapper .dropdown label {
  margin-bottom: 0;
}

.segmentInputAccordion .dropdownWrapper .dropdown.show {
  visibility: visible;
}

.segmentInputAccordion .dropdownWrapper .dropdown.hide {
  visibility: collapse;
}

.segmentContainerWrapper {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.segmentContainerWrapper button:focus {
  box-shadow: none;
}

.segmentContainerWrapper .downArrowWrapper {
  cursor: pointer;
}

.segmentSep {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.NoSegmentWrapper {
  padding-top: 14px;
  padding-bottom: 14px;
  color: gray;
  pointer-events: none;
  background-color: transparent;
  padding-left: 16px;
  font-size: 13.7143px;
  text-transform: uppercase;
}

/*
.segmentContainerWrapper > .arrow {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 0.8571428571428571rem;
  box-sizing: border-box;
  align-items: center;
  font-weight: 400;
  line-height: 1.1876em;
}
*/

.segmentContainer {
  white-space: nowrap;
  overflow: hidden;
}

.segment.edit {
  border-bottom: solid 1px rgb(0, 117, 170) !important;
}

.segment::selection {
  background: lightgrey;
}

.MuiTooltip-arrow::before {
  box-shadow: 2px 2px 0px -1px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

@media (min-width: 600px) {
  .MuiContainer-fixed {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 960px) {
  .MuiContainer-fixed {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-fixed {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1920px) {
  .MuiContainer-fixed {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 0px) {
  .MuiContainer-maxWidthXs {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 600px) {
  .MuiContainer-maxWidthSm {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 960px) {
  .MuiContainer-maxWidthMd {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-maxWidthLg {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}

@media (min-width: 1920px) {
  .MuiContainer-maxWidthXl {
    max-width: 100% !important;
    max-width: var(--bodyWidth) !important;
  }
}
.HelpCenter_helpCenterContent__3OVII {
  z-index: 1;
  width: 100%;
}

.HelpCenter_videoThumbnail__2gwpI {
  position: absolute;
  width: 200px;
  height: 100px;
  opacity: 0.6;
}

.HelpCenter_QAContainer__1_-Dl {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.HelpCenter_question__uTCOs {
  color: #015e88;
  font-size: 20px;
  font-weight: 700;
  overflow-wrap: anywhere;
  padding: 10px 5px;
  text-align: left;
}

.HelpCenter_answer__fbg80 {
  color: #7f7f7f;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
  overflow-wrap: anywhere;
}

.HelpCenter_answer__fbg80 a {
  color: #0075aa;
  text-decoration: underline;
}

.HelpCenter_keyboardArrowDownIcon__BZHYd {
  fill: #0075aa !important;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  display: block;
}

.HelpCenter_keyboardArrowUpIcon__3UxmI {
  fill: #0075aa !important;
  display: block;
}

.HelpCenter_global__1UR_n {
  font-family: Roboto, sans-serif;
}

.HelpCenter_container___JnGa {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px;
  justify-content: space-between;
}

.HelpCenter_releaseContainer__2jDSE {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px;
  justify-content: space-between;
  max-height: 500px;
}
.HelpCenter_title__Qngsv {
  display: flex;
  align-items: center;
  overflow-wrap: anywhere;
}

.HelpCenter_gradientTitle__1KFjq {
  background: -webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  padding-left: 10px;
  margin-left: 10px;
}

.HelpCenter_questionsContainer__cxIBZ {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.HelpCenter_questionItemContainer__1AI4e {
  border: none;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid grey;
}

.HelpCenter_headerContainer__25Wjv {
  display: flex;
  align-items: center;
  height: 32px;
  margin: 10px;
  justify-content: space-between;
}

.HelpCenter_headerFiltersContainer__2G_nX {
  display: flex;
  align-items: center;
  min-height: 32px;
  justify-content: space-between;
  border-radius: 12px;
  padding: 12px 10px 0 0;
}

.HelpCenter_titleQuickReferenceCard__3g9RA {
  margin-left: 10px;
  background: -webkit-linear-gradient(90deg, #620000 30%, #9e2339 70%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 24px;
  padding-left: 10px;
}

.HelpCenter_header__2qJ30 {
  display: flex;
  align-items: center;
}

.HelpCenter_fileHeader__3Y9kc {
  font-weight: 700;
  font-size: 20px;
  color: #015e88;
  padding-left: 20px;
  padding-top: 15px;
  min-height: 45px;
}

.HelpCenter_filterItem__WFM5j {
  display: inline-flex;
  padding: 10px;
}

.HelpCenter_files__NFgqo {
  border-radius: 0 12px 12px 12px;
  background-color: white;
  margin-bottom: 30px;
  min-height: 235px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 25%);
}

.HelpCenter_fileItem__29pwN {
  display: inline-flex;
  padding: 20px;
  width: 230px;
}

.HelpCenter_chipItem__2MjM2 {
  font-size: 16px;
}

.HelpCenter_cardItem__3MSOV {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100px;
  justify-content: center;
  background-color: #f5f5f5;
}

.HelpCenter_mainCard__5M2AH {
  width: 200px;
}

.HelpCenter_wrapperCardItem__3SGlA {
  box-shadow: 0 2px 6px rgb(0 0 0 / 25%);
}

.HelpCenter_wrapperButtonItem__V9AHp {
  max-width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.HelpCenter_downloadFile__35-uB {
  padding: 5px 0;
  text-decoration: none;
  color: #015e88;
  font-weight: 700;
  overflow-wrap: anywhere;
}

.HelpCenter_downloadFileVideoIcon__25G4x {
  padding-left: 10px;
}

.HelpCenter_videoIcon__1Gb33 {
  z-index: 10;
}

.HelpCenter_globalReleaseNote__hxuP1 {
  font-family: Roboto, sans-serif;
  margin-bottom: 30px;
}

.HelpCenter_mainContainer__2l9UU {
  background-color: white;
  border-radius: 12px;
  padding: 32px;
  margin-bottom: 15px;
}

.HelpCenter_itemContainer__1ZRwa {
  border: none;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid grey;
  text-align: left;
}

.HelpCenter_releaseNoteContainer__2lOzp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.HelpCenter_titleName__2k3sJ {
  color: #015e88;
  font-weight: 700;
  font-size: 20px;
  padding: 20px 5px;
}

.HelpCenter_containerMain__1MqM5 {
  align-items: start;
  padding: 24px;
  display: flex;
  font-family: Roboto, sans-serif;
  justify-content: space-between;
}

.HelpCenter_menu__11pFT {
  background-image: linear-gradient(#189ad5, #0075aa);
  width: 176px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 12px;
}

.HelpCenter_menuItem__5aPS7 {
  text-decoration: none;
}

.HelpCenter_menuItem__5aPS7:hover {
  background: linear-gradient(180deg, #0075aa 0%, #004d82 100%);
  text-align: left;
}

.HelpCenter_leftContent__2wjeE {
  margin-right: 24px;
  position: -webkit-sticky;
  position: sticky;
  flex: 0 0 233px;
  top:80px;
  align-items: start;
}

.HelpCenter_content__3ojtv {
  min-width: 0;
  flex: 1 1;
  padding-bottom: 100px;
}

.HelpCenter_text__2EBL2 {
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  padding-right: 10px;
  text-transform: capitalize;
}


.HelpCenter_noRecords__2wwhH {
  padding: 32px;
  overflow-wrap: anywhere;
}

.HelpCenter_cardIconContainer__wy7t_ {
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 190px !important;
  justify-content: flex-end;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0) !important;
  padding: 3px 3px !important;
}

.HelpCenter_cardIcon__XY-tZ {
  margin-left: 3px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: pointer;
}

.HelpCenter_cardIconButton__kC261 {
  height: 16px !important;
  width: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 3px 3px !important;
  border: none !important;
  fill: #0075aa !important;
  font-size: 16px !important;
  background-color: rgba(255, 255, 255, 0) !important;
  cursor: pointer !important;
}
.HelpCenter_emptySection__2Egj4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HelpCenter_emptySectionQRC__i1dTY {
  min-height: 235px;
}

.HelpCenter_emptySectionLabel__1aY7B {
  font-weight: 700;
}

.HelpCenter_noKeywordMatch__3UDrE {
  font-weight: 400;
  font-size: 24px;
  color: #7f7f7f;
  overflow-wrap: break-word;
}

.HelpCenter_adjustKeywords__5u7MS {
  font-size: 16px;
  color: #bdbdbd;
}

.HelpCenter_emptySection__2Egj4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.HelpCenter_emptySectionQRC__i1dTY {
  min-height: 235px;
}

.HelpCenter_emptySectionLabel__1aY7B {
  font-weight: 700;
}

.ProfileSettings_error__HEpce {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileSettings_info__2XCsU {
  color: black;
  font-size: 19px;
  font-weight: 400;
  padding-left: 16px;
  margin-bottom: 0;
  margin-top: 5px;
}

.ProfileSettings_sectionSubTitle__3KROm,
.ProfileSettings_tableHeader__15wOL {
  color: black;
  font-weight: 600;
  font-size: 18px;
}

.ProfileSettings_sectionSubTitle__3KROm {
  margin-top: 0 !important;
}

.ProfileSettings_sectionText__2GbLM {
  margin-top: 5px !important;
  color: #797979;
  font-size: 16px;
}

.ProfileSettings_activeItem__33e0F {
  background-color: #0075aa;
  color: white;
}

.TypeSignature_typeSignature__Vwubc > span {
  width: 433px;
  height: 24px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto",
    sans-serif;
  font-weight: 500;
  color: #000000;
  text-align: left;
  line-height: normal;
}

.TypeSignature_typeSignature__Vwubc {
  width: 471px;
  height: 27px;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  color: #000000;
  text-align: left;
  line-height: normal;
}

.TypeSignature_typeSignatureNameInput__1_sg9 {
  width: 498px;
  margin-bottom: 30px;
  font-family: "Roboto", sans-serif;
  color: #adadad;
  text-align: left;
}

.TypeSignature_rectangle__vcwYO {
  align-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  border: 1px dashed rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  line-height: normal;
  padding: 0 12px;
  text-align: center;
  vertical-align: middle;
  width: 278px;
}

.TypeSignature_selected__2OWEe {
  border: 1px solid #0075aa !important;
}

.TypeSignature_rectangle__vcwYO > div > div > div > canvas {
  max-width: 190px !important;
  cursor: pointer;
}

.TypeSignature_typeSection__336el {
  margin: 32px 147px 33px 249px;
  display: contents;
}

@font-face {
    font-family: 'Freestyle Script';
    font-style: normal;
    font-weight: normal;
    src: local('Freestyle Script'), url("/static/media/Freestyle Script.fce94e3e.woff") format('woff');
}

@font-face {
    font-family: 'Palace Script MT';
    font-style: normal;
    font-weight: normal;
    src: local('Palace Script MT'), url("/static/media/Palace script.4cf86c6e.woff") format('woff');
}

@font-face {
    font-family: 'Pristina';
    font-style: normal;
    font-weight: normal;
    src: local('Pristina'), url(/static/media/Pristina.9ff52359.woff) format('woff');
}

@font-face {
    font-family: 'Lucida Handwriting';
    font-display: swap;
    font-style: italic;
    font-weight: normal;
    src: url(/static/media/LucidaHandwritingStd.90899b21.woff2) format('woff2'),
    url(/static/media/LucidaHandwritingStd.2eb5c00c.woff) format('woff');
}

