/*---------------------------------------------------------
    Footer
---------------------------------------------------------*/
.copyright {
  font-size: 14px;
  margin-left: 16px;
}

footer {
  flex-shrink: 0;
}

footer .links a {
  color: #0075aa;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 5px;
  padding-right: 20px;
}

footer .links a:hover {
  color: #8c8c8c;
  text-decoration: none;
}

footer .socialMediaButtons {
  font-size: 32px;
}

footer .socialMediaButtons a {
  font-size: 32px;
  padding-right: 30px;
  color: #808285;
}

#footerFacebook:hover {
  color: #47639b;
}

#footerTwitter:hover {
  color: #4b9ed7;
}

#footerLinkedIn:hover {
  color: #2174ae;
}

#footerYoutube:hover {
  color: #ee3924;
}

#footerRss:hover {
  color: #fdb515;
}
